// monitoring errors

export const alertOnCheckAllPings = () => {
  alert("Unable to retrieve website status data")
}

export const alertOnAddWebsite = () => {
  alert("Unable to add website")
}

export const alertOnRemoveWebsite = () => {
  alert("Unable to remove website")
}