import "./monitoring.styles"
import Monitoring from "../../components/monitoring/monitoring.component"


const MonitoringPage = () => {
  return (
    <Monitoring/>
  )
}

export default MonitoringPage